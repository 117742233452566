import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICloseModalInfo } from 'interfaces/modals/closeModalInfo.interface';
import { IModalState } from '../../interfaces/modals/modalState.interface';
import { RootState } from 'redux/store';
import { SPINE_MENU_ITEMS } from 'utils/common-constants';
import { MODAL_FOOTER_NAMES, MODAL_NAMES } from 'components/Modals/modal-list';

export const SLICE_KEY = 'modal';

const initialState: IModalState = {
  id: '',
  componentName: MODAL_NAMES.EMPTY,
  componentProps: {},
  withFooter: true,
  footerName: MODAL_FOOTER_NAMES.EMPTY,
  title: '',
  body: '',
  display: false,
  closeInfo: {},
  width: '632px',
  additionalData: undefined,
  sideBarNavigated: undefined,
};

const modalSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<IModalState>) => {
      Object.assign(state, action.payload);
    },
    closeModal: (state, action: PayloadAction<ICloseModalInfo>) => {
      state = Object.assign(state, initialState);
      state.closeInfo = action.payload;
    },
    setAdditionalData: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        additionalData: action.payload,
      };
    },
    setSidebarNavigated: (
      state,
      action: PayloadAction<SPINE_MENU_ITEMS | undefined>
    ) => {
      return {
        ...state,
        sideBarNavigated: action.payload,
      };
    },
  },
});

export const selectModalSlice = (state: any) => state[SLICE_KEY];
export const selectModalName = (state: any) => state.modal.componentName;
export const selectAdditionalData = (state: RootState) =>
  state.modal.additionalData;
export const selectSidebarNavigated = (state: RootState) =>
  state.modal.sideBarNavigated;

export const { openModal, closeModal, setAdditionalData, setSidebarNavigated } =
  modalSlice.actions;

export default modalSlice.reducer;
