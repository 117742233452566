import { Iconsvg, Tooltip, TooltipBody } from '@wk/components-react16';
import style from './InfoIconWithTooltip.module.scss';
import { Useuid } from 'utils/hooks/useUid';

export interface IInfoIconWithTooltip {
  id: string;
  size?: number;
  type?: 'top' | 'right' | null;
  text?: string;
  tooltipPosition?: 'left' | 'right' | 'top' | 'bottom';
  wrapText?: boolean;
}

export const InfoIconWithTooltip = ({
  id,
  size,
  type,
  text,
  tooltipPosition,
  wrapText,
}: IInfoIconWithTooltip) => {
  const uniquieId = Useuid() + id;
  return (
    <>
      <div
        className={
          style[`${type === 'top' ? 'top-positioned' : 'right-positioned'}`]
        }
        id={`InfoIconWithTooltip-${uniquieId}`}
        data-testid="InfoIconWithTooltip"
      >
        <Iconsvg name="info" size={size ?? 18} color="black"></Iconsvg>
      </div>
      <Tooltip
        position={tooltipPosition ?? 'left'}
        targetSelector={`#InfoIconWithTooltip-${uniquieId}`}
        id={`InfoIconWithTooltiptooltip-${uniquieId}`}
      >
        <TooltipBody slot="tooltipBody">
          <span
            className={wrapText ? style['wrap-text'] : ''}
            data-testid="InfoIconWithTooltipText"
          >
            {text ?? 'Value was sourced from BUAdmin'}
          </span>
        </TooltipBody>
      </Tooltip>
    </>
  );
};
