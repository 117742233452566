import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './ParametersDetailsColumn.module.scss';

const ParametersDetailsColumn = () => {
  return (
    <CsbErrorBoundary>
      <div
        data-testid="ParametersDetailsColumn"
        className={style['container']}
      />
    </CsbErrorBoundary>
  );
};

export default ParametersDetailsColumn;
