import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './ParametersListColumn.module.scss';

const ParametersListColumn = () => {
  return (
    <CsbErrorBoundary>
      <div data-testid="ParametersListColumn" className={style['container']}>
        <span className={style['title']}>Module parameters</span>
      </div>
    </CsbErrorBoundary>
  );
};

export default ParametersListColumn;
