import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import CreateModuleNormalTab from './components/CreateModuleNormalTab/CreateModuleNormalTab';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';

interface ICreateModuleModal {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

const CreateModuleModal = ({
  register,
  setValue,
  setError,
  clearErrors,
  errors,
}: ICreateModuleModal) => {
  return (
    <CsbErrorBoundary>
      <div data-testid="CreateModuleModal">
        <CreateModuleNormalTab
          register={register}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          errors={errors}
        />
      </div>
    </CsbErrorBoundary>
  );
};

export default CreateModuleModal;
