import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
  Iconsvg,
} from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MODAL_ACTIONS } from 'interfaces/modals/closeModalInfo.interface';
import React, { useState } from 'react';
import {
  UseFormWatch,
  FieldValues,
  UseFormReset,
  UseFormHandleSubmit,
} from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeModal } from 'redux/modal/ModalSlice';

interface ICancelCreateModuleFooter {
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  handleSubmit: UseFormHandleSubmit<any>;
}

const CancelCreateModuleFooter = ({
  watch,
  reset,
  handleSubmit,
}: ICancelCreateModuleFooter) => {
  const [isLoading] = useState<boolean>(false);
  const modalInfo = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  const handleUserRequest = (action: MODAL_ACTIONS) => {
    if (action === MODAL_ACTIONS.cancel) {
      reset();
      dispatch(
        closeModal({
          id: modalInfo.id,
          action,
        })
      );
    }
  };

  const onSubmit = (
    onSubmit: any
  ): React.MouseEventHandler<HTMLButtonElement> | undefined => {
    throw new Error('Function not implemented.');
  };

  return (
    <CsbErrorBoundary>
      <ButtonGroup mode="static">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'}>
            <button
              type="button"
              id="cancel"
              onClick={() => handleUserRequest(MODAL_ACTIONS.cancel)}
              data-testid="CancelButton"
              disabled={isLoading}
            >
              Cancel
            </button>
          </ButtonField>
        </ButtonGroupItem>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'default'} iconPosition="left">
            <button
              type="button"
              data-testid="SubmitButton"
              id="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={true}
            >
              <Iconsvg name="bolt" />
              <span>Create code</span>
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
};

export default CancelCreateModuleFooter;
