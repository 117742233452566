import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { mapProductToTable } from 'dto/runOrchestration/productToTable';
import { IRunOrchestrationSlice } from 'interfaces/runOrchestration/runOrchestrationState.interface';
import { mapWorkflowToTable } from 'dto/runOrchestration/workflowToTable';
import {
  IWorkflowProduct,
  IWorkflow,
  RUN_ORCHESTRARIONS_STATUS,
} from 'interfaces/runOrchestration/workflowRow';
import { IServerWorkflow } from 'interfaces/workflow.interface';
import { fetchWorkflows } from 'api/workflows/workflowsThunk';
export const SLICE_KEY = 'runOrchestrations';

const initialState: IRunOrchestrationSlice = {
  loading: false,
  workflows: [],
  notFilteredWorkflows: [],
  filterModel: null,
  selectedWorkflow: null,
  wizardActiveIndex: 0,
  isFilteringOnlyActive: false,
  filterQuery: null,
  updatedWorkflow: null,
};

const filterWorkflows = (
  workflows: IWorkflow[] | IWorkflowProduct[],
  searchParam: string
) => {
  let filteredWorkflows: any[] = [];
  if (workflows != null) {
    filteredWorkflows = [...workflows]
      ?.map((workflow: IWorkflow) => {
        const filteredProducts = workflow.products?.filter((product) =>
          product.name?.toLowerCase().includes(searchParam.toLowerCase())
        );
        const workflowFilteredProducts: IWorkflow = {
          ...workflow,
          products: filteredProducts as IWorkflowProduct[],
        };
        const returnArray = [];
        if (
          workflow.name?.toLowerCase().includes(searchParam.toLowerCase()) ||
          filteredProducts?.length
        ) {
          returnArray.push(workflowFilteredProducts);
          filteredProducts &&
            filteredProducts.forEach((product) =>
              returnArray.push(mapProductToTable(product, workflow))
            );
        }
        return returnArray.flat();
      })
      .filter(Boolean);
  }
  return filteredWorkflows.flat();
};

const findInsertIndexForNewWorkflow = (
  baseArray: IWorkflow[],
  newElement: IWorkflow
) => {
  let insertIndex = 0;
  insertIndex = baseArray?.findIndex(
    (w) => !w.isProduct && w.name.localeCompare(newElement.name) > 0
  );
  if (insertIndex === -1) {
    insertIndex = baseArray.length;
  }
  return insertIndex;
};

const runOrchestrationsSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setWizardActiveIndex: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        wizardActiveIndex: state.wizardActiveIndex <= 3 ? action.payload : 3,
      };
    },
    clearState: (state) => {
      return {
        loading: false,
        workflows: [],
        notFilteredWorkflows: [],
        filterModel: null,
        selectedWorkflow: null,
        wizardActiveIndex: 0,
        isFilteringOnlyActive: false,
        filterQuery: null,
        updatedWorkflow: null,
      };
    },
    addWorkflowProducts: (state, action: PayloadAction<IWorkflow>) => {
      const orchestrationParent = action.payload;
      const parentIndex = state.workflows?.findIndex(
        (o) => o.id === orchestrationParent.id
      );
      const newProductsWorkflows: IWorkflowProduct[] =
        state.workflows[parentIndex]?.products
          ?.map((product: any) =>
            mapProductToTable(product, orchestrationParent)
          )
          .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
      let newWorkflows = [...state.workflows];
      if (
        parentIndex > -1 &&
        newWorkflows?.length > 0 &&
        newProductsWorkflows?.length > 0
      ) {
        const uniqueNewProductsWorkflows = newProductsWorkflows.filter(
          (newProduct) =>
            !newWorkflows.some(
              (workflow) =>
                workflow.isProduct &&
                (workflow as IWorkflowProduct).productId ===
                  newProduct.productId
            )
        );
        newWorkflows.splice(parentIndex + 1, 0, ...uniqueNewProductsWorkflows);
      }

      return {
        ...state,
        workflows: newWorkflows,
      };
    },
    removeWorkflowProducts: (state, action: PayloadAction<any>) => {
      const { id, products } = action.payload;
      const parentIndex = state.workflows?.findIndex((o) => o.id === id);
      let newWorkflows = [...state.workflows];
      if (
        parentIndex > -1 &&
        products?.length > 0 &&
        newWorkflows[parentIndex + 1]?.isProduct &&
        newWorkflows[parentIndex + products?.length]?.isProduct
      ) {
        newWorkflows.splice(parentIndex + 1, products?.length);
      }

      return {
        ...state,
        workflows: newWorkflows,
      };
    },
    setSelectedWorkflow: (
      state,
      action: PayloadAction<IWorkflow | IWorkflowProduct | null>
    ) => {
      return {
        ...state,
        selectedWorkflow: action.payload,
      };
    },
    toggleFilteringOnlyActive: (state) => {
      const isDisplayingOnlyActive = !state.isFilteringOnlyActive;
      if (isDisplayingOnlyActive === true) {
        let filteredWorkflows: IWorkflow[] | IWorkflowProduct[] =
          state.workflows.filter(
            (item: IWorkflow | IWorkflowProduct) =>
              item.status === RUN_ORCHESTRARIONS_STATUS.ACTIVE
          );
        return {
          ...state,
          isFilteringOnlyActive: isDisplayingOnlyActive,
          workflows: filteredWorkflows,
        };
      } else {
        if (state.filterQuery != null && state.filterQuery !== '') {
          let filteredWorkflows: IWorkflow[] | IWorkflowProduct[] =
            filterWorkflows(state.notFilteredWorkflows, state.filterQuery);
          return {
            ...state,
            isFilteringOnlyActive: isDisplayingOnlyActive,
            workflows: filteredWorkflows,
          };
        } else {
          return {
            ...state,
            isFilteringOnlyActive: isDisplayingOnlyActive,
            workflows: state.notFilteredWorkflows,
          };
        }
      }
    },

    searchFilter: (state, action: PayloadAction<string | null>) => {
      const searchParams = action.payload;
      if (!searchParams) {
        return {
          ...state,
          filterQuery: searchParams,
          workflows: state.isFilteringOnlyActive
            ? state.notFilteredWorkflows.filter(
                (item: IWorkflow | IWorkflowProduct) =>
                  item.status === RUN_ORCHESTRARIONS_STATUS.ACTIVE
              )
            : state.notFilteredWorkflows,
        };
      }
      let filteredWorkflows: IWorkflow[] | IWorkflowProduct[] = filterWorkflows(
        state.notFilteredWorkflows,
        searchParams
      );
      return {
        ...state,
        filterQuery: searchParams,
        workflows: state.isFilteringOnlyActive
          ? filteredWorkflows.filter(
              (item: IWorkflow | IWorkflowProduct) =>
                item.status === RUN_ORCHESTRARIONS_STATUS.ACTIVE
            )
          : filteredWorkflows,
      };
    },
    addNewWorkflow: (state, action: PayloadAction<IServerWorkflow>) => {
      let workflow: IWorkflow = mapWorkflowToTable(action.payload);
      workflow.products = workflow.products
        ?.map((product) => mapProductToTable(product, workflow))
        .sort((a, b) => a.name.localeCompare(b.name));

      const existingWorkflowIndex = state.workflows.findIndex(
        (w) => w.name === workflow.name
      );

      if (existingWorkflowIndex !== -1) {
        let newWorkflows = [...state.workflows];
        let newNotFilteredWorkflows = [...state.notFilteredWorkflows];
        newWorkflows[existingWorkflowIndex] = workflow;
        const notFilteredIndex = newNotFilteredWorkflows.findIndex(
          (w) => w.name === workflow.name
        );
        if (notFilteredIndex !== -1) {
          newNotFilteredWorkflows[notFilteredIndex] = workflow;
        }
        return {
          ...state,
          workflows: newWorkflows,
          notFilteredWorkflows: newNotFilteredWorkflows,
          updatedWorkflow: action.payload,
        };
      } else {
        if (state.filterQuery != null && state.filterQuery !== '') {
          const notFilteredWorkflows: IWorkflow[] = state.notFilteredWorkflows;
          const updatedFilteredWorkflows = [...state.workflows];
          const updatedWorkflows = [...notFilteredWorkflows];
          let insertIndex = findInsertIndexForNewWorkflow(
            updatedFilteredWorkflows,
            workflow
          );
          updatedFilteredWorkflows.splice(insertIndex, 0, workflow);
          insertIndex = findInsertIndexForNewWorkflow(
            updatedWorkflows,
            workflow
          );
          updatedWorkflows.splice(insertIndex, 0, workflow);
          return {
            ...state,
            workflows: updatedFilteredWorkflows,
            notFilteredWorkflows: updatedWorkflows,
          };
        } else {
          const updatedWorkflows = [...state.workflows];
          let insertIndex = findInsertIndexForNewWorkflow(
            updatedWorkflows,
            workflow
          );
          updatedWorkflows.splice(insertIndex, 0, workflow);
          return {
            ...state,
            workflows: updatedWorkflows,
            notFilteredWorkflows: updatedWorkflows,
            updatedWorkflow: action.payload,
          };
        }
      }
    },
    deleteWorkflow: (state, action: PayloadAction<{ workflowId: number }>) => {
      if (state.filterQuery != null && state.filterQuery !== '') {
        const notFilteredWorkflows: IWorkflow[] = state.notFilteredWorkflows;
        const updatedWorkflows = [...notFilteredWorkflows].filter(
          (workflow) => workflow.id !== action.payload.workflowId
        );
        const updatedFilteredWorkflows = [...state.workflows].filter(
          (workflow) => workflow.id !== action.payload.workflowId
        );
        return {
          ...state,
          workflows: updatedFilteredWorkflows,
          notFilteredWorkflows: updatedWorkflows,
        };
      } else {
        const updatedWorkflows = [...state.workflows].filter(
          (workflow) => workflow.id !== action.payload.workflowId
        );
        return {
          ...state,
          workflows: updatedWorkflows,
          notFilteredWorkflows: updatedWorkflows,
        };
      }
    },
    updateWorkflow: (state, action: PayloadAction<IServerWorkflow>) => {
      let workflow: IWorkflow = mapWorkflowToTable(action.payload);
      workflow.products?.forEach((product) =>
        mapProductToTable(product, workflow)
      );
      let addedProducts: any[] = [];
      if (state.filterQuery != null && state.filterQuery !== '') {
        const notFilteredWorkflows: IWorkflow[] = state.notFilteredWorkflows;

        const updatedWorkflows = [...notFilteredWorkflows]
          ?.map((w) => {
            if (w.id === workflow.id) {
              return workflow;
            } else if (
              w.isProduct &&
              (w as IWorkflowProduct).parentId === workflow.id
            ) {
              if (addedProducts.length === 0) {
                addedProducts = [...(workflow.products || [])];
                return workflow.products
                  ?.map((product) => mapProductToTable(product, workflow))
                  .sort((a, b) => a.name.localeCompare(b.name));
              } else {
                return null as any;
              }
            } else {
              return w;
            }
          })
          .flat()
          .filter((x) => x !== null && x !== undefined);
        addedProducts = [];
        const updatedFilteredWorkflows = [...state.workflows]
          ?.map((w) => {
            if (w.id === workflow.id) {
              return workflow;
            } else if (
              w.isProduct &&
              (w as IWorkflowProduct).parentId === workflow.id
            ) {
              if (addedProducts.length === 0) {
                addedProducts = [...(workflow.products || [])];
                return workflow.products
                  ?.map((product) => mapProductToTable(product, workflow))
                  .sort((a, b) => a.name.localeCompare(b.name));
              } else {
                return null as any;
              }
            } else {
              return w;
            }
          })
          .flat()
          .filter((x) => x !== null && x !== undefined);
        return {
          ...state,
          notFilteredWorkflows: updatedWorkflows,
          workflows: state.isFilteringOnlyActive
            ? [...updatedFilteredWorkflows].filter(
                (item: IWorkflow | IWorkflowProduct) =>
                  item.status === RUN_ORCHESTRARIONS_STATUS.ACTIVE
              )
            : updatedFilteredWorkflows,
          updatedWorkflow: action.payload,
        };
      } else {
        const updatedNotFilteredWorkflows = state.notFilteredWorkflows
          .map((w) => {
            if (w.id === workflow.id) {
              return workflow;
            } else if (
              w.isProduct &&
              (w as IWorkflowProduct).parentId === workflow.id
            ) {
              if (addedProducts.length === 0) {
                addedProducts = [...(workflow.products || [])];
                return workflow.products
                  ?.map((product) => mapProductToTable(product, workflow))
                  .sort((a, b) => a.name.localeCompare(b.name));
              } else {
                return null as any;
              }
            } else {
              return w;
            }
          })
          .flat()
          .filter((x) => x !== null && x !== undefined);
        addedProducts = [];
        const updatedWorkflows = [...state.workflows]
          ?.map((w) => {
            if (w.id === workflow.id) {
              return workflow;
            } else if (
              w.isProduct &&
              (w as IWorkflowProduct).parentId === workflow.id
            ) {
              if (addedProducts.length === 0) {
                addedProducts = [...(workflow.products || [])];
                return workflow.products
                  ?.map((product) => mapProductToTable(product, workflow))
                  .sort((a, b) => a.name.localeCompare(b.name));
              } else {
                return null as any;
              }
            } else {
              return w;
            }
          })
          .flat()
          .filter((x) => x !== null && x !== undefined);
        return {
          ...state,
          notFilteredWorkflows: updatedNotFilteredWorkflows,
          workflows: state.isFilteringOnlyActive
            ? [...updatedWorkflows].filter(
                (item: IWorkflow | IWorkflowProduct) =>
                  item.status === RUN_ORCHESTRARIONS_STATUS.ACTIVE
              )
            : [...updatedWorkflows],
          updatedWorkflow: action.payload,
        };
      }
    },
    resetUpdatedWorkflow: (state) => {
      return {
        ...state,
        updatedWorkflow: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkflows.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchWorkflows.fulfilled, (state, action: any) => {
      if (action.payload?.length > 0) {
        let workflows: IWorkflow[] = (action.payload as IServerWorkflow[])?.map(
          (workflow: IServerWorkflow) => mapWorkflowToTable(workflow)
        );
        workflows = workflows.map((workflow: IWorkflow) => {
          const products = workflow.products?.map((product) =>
            mapProductToTable(product, workflow)
          );
          products?.sort((a, b) => a.name.localeCompare(b.name));
          return { ...workflow, products };
        });
        if (state.filterQuery != null && state.filterQuery !== '') {
          let filteredWorkflows: IWorkflow[] | IWorkflowProduct[] =
            filterWorkflows(state.notFilteredWorkflows, state.filterQuery);
          return {
            ...state,
            loading: false,
            workflows: state.isFilteringOnlyActive
              ? [...filteredWorkflows].filter(
                  (item: IWorkflow | IWorkflowProduct) =>
                    item.status === RUN_ORCHESTRARIONS_STATUS.ACTIVE
                )
              : filteredWorkflows,
            notFilteredWorkflows: workflows,
          };
        } else {
          return {
            ...state,
            loading: false,
            notFilteredWorkflows: workflows,
            workflows: state.isFilteringOnlyActive
              ? [...workflows].filter(
                  (item: IWorkflow | IWorkflowProduct) =>
                    item.status === RUN_ORCHESTRARIONS_STATUS.ACTIVE
                )
              : workflows,
          };
        }
      }
    });
    builder.addCase(fetchWorkflows.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

export const selectIsFileringOnlyActive = (state: RootState) =>
  state.runOrchestrations.isFilteringOnlyActive;

export const selectWorkflows = (state: RootState) =>
  state.runOrchestrations.workflows;
export const selectRunOrchestrationLoading = (state: RootState) =>
  state.runOrchestrations.loading;

export const selectSelectedWorkflow = (state: RootState) =>
  state.runOrchestrations.selectedWorkflow;

export const selectWizardActiveIndex = (state: RootState) =>
  state.runOrchestrations.wizardActiveIndex;

export const selectFilterQuery = (state: RootState) =>
  state.runOrchestrations.filterQuery;

export const selectUpdatedWorkflow = (state: RootState) =>
  state.runOrchestrations.updatedWorkflow;

export const selectWorkflowsIdsAndType = (state: RootState) =>
  state.runOrchestrations.workflows.map((workflow) => ({
    id: workflow.id,
    isProduct: workflow.isProduct,
  }));

export type SelectIsFileringOnlyActiveType = ReturnType<
  typeof selectIsFileringOnlyActive
>;
export type SelectWorkflowsType = ReturnType<typeof selectWorkflows>;
export type SelectSelectedWorkflowType = ReturnType<
  typeof selectSelectedWorkflow
>;

export const {
  setWizardActiveIndex,
  addWorkflowProducts,
  removeWorkflowProducts,
  clearState,
  setSelectedWorkflow,
  toggleFilteringOnlyActive,
  searchFilter,
  addNewWorkflow,
  deleteWorkflow,
  updateWorkflow,
  resetUpdatedWorkflow,
} = runOrchestrationsSlice.actions;

export default runOrchestrationsSlice.reducer;
