import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
} from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeModal } from 'redux/modal/ModalSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MODAL_ACTIONS } from 'interfaces/modals/closeModalInfo.interface';
import {
  consoleErrorMessage,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import { MANAGE_MODULES_BUTTONS } from 'interfaces/modals/manageModuleButtonsModal.interface';
import {
  IModuleServer,
  ManageModuleTablesTypes,
  MODULES_TABS,
} from 'interfaces/modules/module.interface';

import { useState } from 'react';
import {
  selectCurrentTab,
  selectSelectedModule,
  selectSelectedModuleInTenantTable,
} from 'redux/manageModules/ManageModulesSlice';
import { IServerWorkflow } from 'interfaces/workflow.interface';
import {
  removeAllModules,
  restartAllModules,
  stopAllModules,
} from 'api/manageModules/manageModulesThunks';

export interface IManageModuleButtonsFooter {
  actionType: MANAGE_MODULES_BUTTONS;
  reset: () => void;
}

const buttonTexts = {
  [MANAGE_MODULES_BUTTONS.stop]: 'Stop',
  [MANAGE_MODULES_BUTTONS.restart]: 'Restart',
  [MANAGE_MODULES_BUTTONS.remove]: 'Remove',
};

export const ManageModuleButtonsFooter = ({
  actionType,
  reset,
}: IManageModuleButtonsFooter) => {
  const dispatch = useAppDispatch();
  const modalInfo = useAppSelector((state) => state.modal);
  const currentTab: ManageModuleTablesTypes = useAppSelector(selectCurrentTab);
  const selectedModule: IModuleServer = useAppSelector(
    currentTab === MODULES_TABS.MODULE_LIST
      ? selectSelectedModule
      : selectSelectedModuleInTenantTable
  );
  const [isLoading, setIsLoading] = useState(false);

  const close = () => {
    reset();
    dispatch(
      closeModal({
        id: modalInfo.id,
        action: MODAL_ACTIONS.cancel,
      })
    );
  };

  const showSuccessToast = (text: string) => {
    showToastAndClose('success', 'informationToast', () => {}, dispatch, {
      text,
    });
  };

  const showErrorToast = (text: string) => {
    showToastAndClose('error', 'informationToast', () => {}, dispatch, {
      text,
    });
  };

  const handleRemoveResponse = (response: IServerWorkflow[]) => {
    if (response.length > 0) {
      const activeWorkflows = response
        .map((workflow: IServerWorkflow) => workflow.name)
        .join(', ');
      showErrorToast(
        `Unable to remove module: Orchestrations using this module: ${activeWorkflows}.`
      );
    } else {
      showSuccessToast('Your module has been removed.');
    }
  };

  const handleAction = async () => {
    setIsLoading(true);
    try {
      if (actionType === MANAGE_MODULES_BUTTONS.stop) {
        console.log(selectedModule);
        await dispatch(
          stopAllModules({
            moduleId: selectedModule.id,
            slave: selectedModule.slave,
          })
        ).unwrap();
        showSuccessToast(
          'Your module has been stopped. It may take a while for the status to be updated.'
        );
      } else if (actionType === MANAGE_MODULES_BUTTONS.restart) {
        await dispatch(
          restartAllModules({
            moduleId: selectedModule.id,
            slave: selectedModule.slave,
          })
        ).unwrap();
        showSuccessToast('Your Module has been restarted.');
      } else if (actionType === MANAGE_MODULES_BUTTONS.remove) {
        const response = await dispatch(
          removeAllModules({
            moduleId: selectedModule.id,
            slave: selectedModule.slave,
          })
        ).unwrap();
        handleRemoveResponse(response);
      }
    } catch (error) {
      showErrorToast(
        consoleErrorMessage(error) ??
          `Unable to ${actionType} module: An unknown error occurred.`
      );
    }
    setIsLoading(false);
    close();
  };

  return (
    <CsbErrorBoundary>
      <ButtonGroup mode="static">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'}>
            <button
              type="button"
              id="cancel"
              onClick={() => close()}
              data-testid="CancelBtn"
              disabled={isLoading}
            >
              Cancel
            </button>
          </ButtonField>
        </ButtonGroupItem>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'default'}>
            <button
              type="button"
              data-testid={'ActionBtn'}
              id="button"
              onClick={handleAction}
              disabled={isLoading}
            >
              {buttonTexts[actionType]}
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
};
