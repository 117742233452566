import { LicenseManager } from 'ag-grid-enterprise';
import {
  usePostLoginMutation,
  usePostLoginNoCredentialsMutation,
} from 'api/auth/authApi';
import { useEffect, useMemo } from 'react';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';
import Config from '../../config.json';

export const useLogin = () => {
  const [login, { isLoading: isLoging }] = usePostLoginMutation();
  const [loginNoCredentials, { isLoading: isLoginNoCredentials }] =
    usePostLoginNoCredentialsMutation();

  const isLoading = useMemo(
    () => isLoging || isLoginNoCredentials,
    [isLoging, isLoginNoCredentials]
  );

  const sendLoginNoCredentials = async () => {
    try {
      const response = await loginNoCredentials(null).unwrap();
      window.location.href = response.redirect;
    } catch (error) {
      localStorage.setItem('authError', 'TRUE');
      consoleErrorMessage(error);
    }
  };

  const sendLoginNoCredentialsNoRedirect = async () => {
    try {
      loginNoCredentials(null).unwrap();
    } catch (error) {
      localStorage.setItem('authError', 'TRUE');
      consoleErrorMessage(error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');

    login(null)
      .unwrap()
      .then(() => {
        localStorage.setItem('authError', '');
      })
      .catch((error) => {
        localStorage.setItem('authError', 'TRUE');
        if (error.status === 401 || error.status === 403) {
          if (!code) {
            sendLoginNoCredentials();
          } else {
            sendLoginNoCredentialsNoRedirect();
          }
        } else if (error.status === 500 && code) {
          queryParams.delete('code');
          login(null);
        }
      });

    LicenseManager.setLicenseKey(Config?.REACT_APP_AG_GRID_LICENCE);
  }, [login]);

  return { isLoading };
};
