import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
} from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeModal } from 'redux/modal/ModalSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MODAL_ACTIONS } from 'interfaces/modals/closeModalInfo.interface';
import {
  consoleErrorMessage,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import { useDeleteWorkflowMutation } from 'api/workflows/workflows';
import { ToastStatus } from 'interfaces/toasts';
import { UNEXPECTED_ERROR_MESSAGE } from 'utils/common-constants';

export interface IRunOrchestrationOptionFooter {
  id: number;
  reset: () => void;
  productId?: number;
}

export const RunOrchestrationOptionFooter = ({
  id,
  reset,
  productId,
}: IRunOrchestrationOptionFooter) => {
  const dispatch = useAppDispatch();
  const modalInfo = useAppSelector((state) => state.modal);
  const [deleteWorkflow, { isLoading }] = useDeleteWorkflowMutation();
  const isProduct = productId ? true : false;
  const PRODUCT_VARIANT = 'product variant';
  const ORCHESTRATION = 'orchestration';

  const close = () => {
    reset();
    dispatch(
      closeModal({
        id: modalInfo.id,
        action: MODAL_ACTIONS.cancel,
      })
    );
  };

  const handleRemoveResponse = (response: any) => {
    let toastStatus: ToastStatus = 'error';
    if (response.error) {
      let errorText = response.error?.data ?? 'Error';
      if (errorText.includes('running')) {
        errorText =
          'Orchestration cannot be deleted as associated orchestration instances are in progress.';
        toastStatus = 'info';
      }
      showToastAndClose(
        toastStatus,
        'informationToast',
        (...args) => {},
        dispatch,
        { text: errorText }
      );
    } else {
      toastStatus = 'info';
      showToastAndClose(
        toastStatus,
        'informationToast',
        (...args) => {},
        dispatch,
        {
          text: `Your ${
            isProduct ? PRODUCT_VARIANT : ORCHESTRATION
          } and all associated pending orchestration instances have been deleted`,
        }
      );
    }
  };

  const handleDelete = async () => {
    if (id) {
      try {
        const response: any = await deleteWorkflow({
          id,
          productId: productId ?? undefined,
        });
        handleRemoveResponse(response);
      } catch (error) {
        const errorText = consoleErrorMessage(error);
        showToastAndClose(
          'error',
          'informationToast',
          (...args) => {},
          dispatch,
          { text: errorText ?? UNEXPECTED_ERROR_MESSAGE }
        );
      }
      close();
    }
  };

  return (
    <CsbErrorBoundary>
      <ButtonGroup mode="static">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'}>
            <button
              type="button"
              id="cancel"
              onClick={() => close()}
              data-testid="CancelBtn"
              disabled={isLoading}
            >
              Cancel
            </button>
          </ButtonField>
        </ButtonGroupItem>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'default'}>
            <button
              type="button"
              data-testid={'ActionBtn'}
              id="button"
              onClick={handleDelete}
              disabled={isLoading}
            >
              Delete
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
};
